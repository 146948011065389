export enum SessionStorage {
  // Auth
  RefreshToken = 'refresh_token',
  SessionId = 'session_id',
  JWT = 'access_token',
  DefaultOrg = 'view_org',
  Exp = 'exp',
  ConditionalAccess = 'conditional_access',

  // Registarion / Reset Password
  RegisrationUserId = 'regisration_user_Id',
  RegistrationSessionId = 'regisration_session_Id',
  ResetPasswordRefreshToken = 'reset_psw_refresh_token',
  ResetPassword = 'reset_password',
  ResetSessionId = 'reset_psw_session_Id',
  ResetPasswordFullRecovery = 'reset_password_full_recovery',

  // Prospect
  ProspectnUserId = 'prospect_user_Id',

  // Layout flags for the complicated Welcome page
  WelcomeDashboardDisplay = 'welcome_dashboard_display',
  SwitchOrgRunning = 'switch_org_running',
  DowloadedReportFromRedirect = 'dowloaded_report_from_redirect',

  // Findings
  FindingCreateSystem = 'finding_create_system',
  FindingCreateLabel = 'finding_create_label',
}
